import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Seo from '@activebrands/core-web/components/seo-meta/structured-data/Seo';
import { styled } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    zIndex: 1,
    transition: 'transform var(--transition-fast)',
    overflowY: 'scroll',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'var(--color-text-inverted)',
});

const SiteSelectorLayout = ({ children, location }) => {
    const { seo = {} } = useSiteSelectorQuery();
    const seoData = { content: { seo } };

    return (
        <>
            <Seo data={seoData} location={location} />
            <Wrapper>{children}</Wrapper>
        </>
    );
};

SiteSelectorLayout.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object,
};

export default SiteSelectorLayout;
